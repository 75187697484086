/* eslint-disable */
import { Modal } from "@stellar/design-system";
import { Button, InfoBlock } from "@stellar/design-system";
import { ModalPageProps } from "types/types";

export const OnLandingForm = ({ onClose }: ModalPageProps) => {

  return (
    <>
      <Modal.Heading>Welcome to Stellar Staking</Modal.Heading>

      <Modal.Body>
        <InfoBlock>
          <p>The staking algorithm combines inverse proportional distribution with dutch auction system.</p>
          <p>Fewer users staking means a higher reward percentage (depending on user registration, maximum is set at 25%) and the more users sign up and stake their XLM, the reward percentage decreases accordingly.</p>
          <p>To access the staking feature, you must sign into your account and select "Enable Staking".</p>
          <p>Increments are paid at the end of each 24 hour cycle - UTC time.</p>
        </InfoBlock>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} style={{marginRight: "auto", marginLeft: "auto"}}>
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
};